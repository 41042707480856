a.navbar-brand {
  white-space: nowrap;
  text-align: center;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

a.nav-link {
  max-width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.nav-link:hover,
a.navbar-brand:hover{
  background-color: rgba(0,0,0,0) !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

@media (min-width: 992px) {
  a.nav-link {
    max-width: 200px;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
